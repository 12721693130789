//e.g.  
//Logger.info("log title", "content1","content2"...);

import dayjs from 'dayjs';

const Logger = {
    //trace for debug
    trace : (title, ...info) => {
        if(mode === 'normal'){
            let now = dayjs();
            console.trace("%c"+ title + " %c@" + now.format('HH:mm:ss') + '.' + now.millisecond() + "\n", 'color: #43bb88;font-size: 13px;', 'color: grey;font-size: 12px;', ...info);
        }
    },

    error : (title, ...info) => {
        let now = dayjs();
        console.error("%c"+ title + " %c@" + now.format('HH:mm:ss') + '.' + now.millisecond() + "\n", 'color: #c34348;font-size: 13px;', 'color: grey;font-size: 12px;', ...info);
    },

    info : (title, ...info) => {
        if(mode === 'normal'){
            let now = dayjs();
            console.log("%c"+ title + " %c@" + now.format('HH:mm:ss') + '.' + now.millisecond() + "\n", 'color: #55a5ed;font-size: 13px;', 'color: grey;font-size: 12px;', ...info);
        }
    },

    warn : (title, ...info) => {
        let now = dayjs();
        console.warn("%c"+ title + " %c@" + now.format('HH:mm:ss') + '.' + now.millisecond() + "\n", 'color: #f1e05a;font-size: 13px;', 'color: grey;font-size: 12px;', ...info);
    },

    //set title color
    color : (title, color , ...info) => {
        if(mode === 'normal'){
            let now = dayjs();
            console.log("%c"+ title + " %c@" + now.format('hh:mm:ss') + '.' + now.millisecond() + "\n", 'color: '+ color +';font-size: 14px;', 'color: grey;font-size: 12px;', ...info);
        }
    },
};


/* todo */
let mode = 'normal'; //normal/product   normal模式将会打印打印所有日志/product模式将会屏蔽info,color,trace级别的日志

const setLoggerMode = (m = 'normal') => {
    mode = m;
}


export {setLoggerMode, Logger};

