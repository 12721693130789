
import axios from "axios";

export const mode = "run"; //debug & run

export const getUrlParam = (variable, url = window.location.href) => {
    try {
        var query = url.split('?')[1];
        // console.log(query);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] === variable) { return pair[1]; }
        }
    } catch (e) {
        return false;
    }

    return false;
}

// export const aiUrl = mode === 'debug' ? defaultPrefix : getUrlParam('prefix');
/*
如果没有安装webpack，就使用下面这种写法
axios.defaults.baseURL = http://ip:端口
*/

let protocol = window.location.protocol
let host = window.location.host
let windowUrl = host.indexOf('localhost') != -1 ?(window.API_U +window.API_P):(protocol+ '//'+ host)
let path = window.API_PATH
console.log(windowUrl + '------')

export const instance = axios.create({
    baseURL: windowUrl + path,
    timeout: 100000,
    withCredentials: true, //set cookie  (session)
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    }
});

export const fetchData = (type, url, param) => {
    let retData = {};

    let commonHeader = {
        // 'Authorization':'eyJ0eXAiOiJqd3QiLCJhbGciOiJIUzI1NiJ9.eyJkaXNwbGF5TmFtZSI6IueJm-S6riIsIm5hbWUiOiJuaXVsaWFuZyIsImV4cCI6MTYyMzY1MTcwOSwiaWF0IjoxNjIzNTY1MzA5LCJ1dWlkIjoiODRjNWEzYWQtY2U2ZC00MjIyLWJhOTQtMWRhMzhmNzA0ZDJhIn0.JerQwzJ53OMldMqs_0RdHmi13R9E3AxU6BEfjne8O6M'
    }

    if ('get' === type) {
        if (param) {
            url = url + '?'
            for (let key of Object.keys(param)) {
                url = url + key + '=' + param[key] + '&'
            }
        }
        return (
            instance({
                method: 'get',
                url: url,
            }).then((response) => {
                retData.data = response.data;
                if (response.data.code === 200) {
                    retData.code = 1;
                    retData.data = response.data;
                } else {
                    retData.code = -1;
                    retData.message = response.data.message;
                }
                return retData;
            }).catch((e) => {
                retData.code = -1;
                retData.data = 'axios or network error';
                if (!e.response) return retData;
                switch (e.response.data.code) {
                    case 401: {
                        window.localStorage.removeItem('zhToken');
                        window.location.reload();
                        break;
                    }
                    case 400:
                    case 402:
                    case 500: {
                        retData.message = e.response.data.message
                        break;
                    }
                    case 9000: {
                        break;
                    }
                    default: {
                    }
                }
                return retData;
            })
        );
    } else if ('postJson' === type) {
        return (
            instance({
                method: 'post',
                url: url,
                data: param,
                headers: commonHeader
            }).then((response) => {
                retData.data = response.data;
                if (response.data.code === 200) {
                    retData.code = 1;
                    retData.data = response.data;
                } else {
                    retData.code = -1;
                    retData.message = response.data.message;
                }
                return retData;
            }).catch((e) => {
                retData.code = -1;
                retData.data = 'axios or network error';
                if (!e.response) return retData;
                switch (e.response.data.code) {
                    case 401: {
                        window.localStorage.removeItem('zhToken');
                        window.location.reload();
                        break;
                    }
                    case 400:
                    case 402:
                    case 500: {
                        retData.message = e.response.data.message
                        break;
                    }
                    case 9000: {
                        break;
                    }
                    default: {
                    }
                }
                return retData;
            })
        );
    } else if ('post' === type) {
        return (
            instance({
                method: 'post',
                url: url,
                data: param,
                headers: commonHeader
            }).then((response) => {
                retData.data = response.data;
                if (response.data.code === 200) {
                    retData.code = 1;

                } else {
                    retData.code = -1;
                    retData.message = response.data.message;
                }
                return retData;
            }).catch((e) => {
                retData.code = -1;
                retData.data = 'axios or network error';
                if (!e.response) return retData;
                switch (e.response.data.code) {
                    case 401: {
                        window.localStorage.removeItem('zhToken');
                        window.location.reload();
                        break;
                    }
                    case 400:
                    case 402:
                    case 500: {
                        retData.message = e.response.data.message
                        break;
                    }
                    case 9000: {
                        break;
                    }
                    default: {
                    }
                }
                return retData;
            })
        );
    } else if ('put' === type) {
        return (
            instance({
                method: 'put',
                url: url,
                data: param,
                headers: commonHeader
            }).then((response) => {
                retData.data = response.data;
                if (response.data.code === 200) {
                    retData.code = 1;
                    retData.data = response.data;
                } else {
                    retData.code = -1;
                    retData.message = response.data.message;
                }
                return retData;
            }).catch((e) => {
                retData.code = -1;
                retData.data = 'axios or network error';
                if (!e.response) return retData;
                switch (e.response.data.code) {
                    case 401: {
                        window.localStorage.removeItem('zhToken');
                        window.location.reload();
                        break;
                    }
                    case 400:
                    case 402:
                    case 500: {
                        retData.message = e.response.data.message
                        break;
                    }
                    case 9000: {
                        break;
                    }
                    default: {
                    }
                }
                return retData;
            })
        );
    } else if ('delete' === type) {
        return (
            instance({
                method: 'delete',
                url: url,
                data: param,
                headers: commonHeader
            }).then((response) => {
                retData.data = response.data;
                if (response.data.code === 200) {
                    retData.code = 1;
                    retData.data = response.data;
                } else {
                    retData.code = -1;
                    retData.message = response.data.message;
                }
                return retData;
            }).catch((e) => {
                retData.code = -1;
                retData.data = 'axios or network error';
                if (!e.response) return retData;
                switch (e.response.data.code) {
                    case 401: {
                        window.localStorage.removeItem('zhToken');
                        window.location.reload();
                        break;
                    }
                    case 400:
                    case 402:
                    case 500: {
                        retData.message = e.response.data.message
                        break;
                    }
                    case 9000: {
                        break;
                    }
                    default: {
                    }
                }
                return retData;
            })
        );
    }
};
export const uploadData = (type, url, param, progress) => {
    let retData = {};
    const configs = {
        headers: { 'Content-Type': 'multipart/form-data', 'Authorization': instance.defaults.headers.common['Authorization'] },
        withCredentials: true,
        onUploadProgress: progress
    }

    let URL = windowUrl + path + url
    if ('post' === type) {
        return axios.post(URL, param, configs).then(response => {
            retData.data = response.data;
            if (response.data.code === 200) {
                retData.code = 1;
            } else {
                retData.code = -1;
                retData.message = response.data.message;
            }
            return retData;
        })

            .finally(log => {
                console.log(log);
            }).catch((e) => {
                retData.code = -1;
                retData.data = 'axios or network error';
                if (!e.response) return retData;
                switch (e.response.data.code) {
                    case 401: {
                        window.localStorage.removeItem('zhToken');
                        window.location.reload();
                        break;
                    }
                    case 400:
                    case 402:
                    case 500: {
                        retData.message = e.response.data.message
                        break;
                    }
                    case 9000: {
                        break;
                    }
                    default: {
                    }
                }
                return retData;
            })
    } else {
        return axios.put(URL, param, configs).then(response => {
            retData.data = response.data;
            if (response.data.code === 200) {
                retData.code = 1;
            } else {
                retData.code = -1;
                retData.message = response.data.message;
            }
            return retData;
        })

            .finally(log => {
                console.log(log);
            }).catch((e) => {
                retData.code = -1;
                retData.data = 'axios or network error';
                if (!e.response) return retData;
                switch (e.response.data.code) {
                    case 401: {
                        window.localStorage.removeItem('zhToken');
                        window.location.reload();
                        break;
                    }
                    case 400:
                    case 402:
                    case 500: {
                        retData.message = e.response.data.message
                        break;
                    }
                    case 9000: {
                        break;
                    }
                    default: {
                    }
                }
                return retData;
            })
    }



};

export const SetToken2 = (token) => {
    instance.defaults.headers.common['Authorization'] = token;
}

export default fetchData;

