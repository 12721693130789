import './login.less';
import React from 'react';
import { connect } from "react-redux";
import { Button, Input, Form } from 'antd';
import Tooltip from "antd/es/tooltip";
import { ApiUtil, SetToken } from "../../utils/ApiUtils";
import { fetchData, SetToken2 } from "../../utils/fetchData";
import { encrypt } from "../../utils/EncryptUtils";
import { createUuid } from '../../utils/CommonUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShieldAlt, faKey, faUser } from '@fortawesome/free-solid-svg-icons'
import Bg from '../../assets/sign_in_bg.jpg'

const inputCss = { fontSize: '14px', height: 36, borderRadius: '8px/14px', paddingLeft: 15, fontWeight: 200 };
const faIconCss = { color: '#00000066' };
class Login extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            authCode: '',
            captchaKey: ''
        }
    }

    componentDidMount() {
        this.refreshCode();
    }

    setEncrypt = (msg) => {
        var jsencrypt = new window.JSEncrypt();
        jsencrypt.setPublicKey("MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyIZzn4Plfih7oisz1ouUMa1rUAPwtA6KxhSYaILv4euZb02SJDQgpnNvXJ3UjT2DAbzOG/9LnJivZo6TpyOSE+zvRS1nYvbtIGgMckW4QPRQt0B/G9NKshUXGrkWNEt/kwHlNL5KcBKzBvWdYyXvGQz422vId7jIZfnzIicXtsVHxTKe1Zi+i92/AiD5umkEqGl0TCrmAsROgae2EMjR/VGoNO8o3Rvmp8hBAYCavTML644FPGEoCGEB4Bq2L8P0UJT+qEZnkeA6V45yfQ33InWLPjxg/6WUx59FxQti7vS5ZGPgR90LDUu8KUaSpsc4a6XuC9vUukWTtZxEt2Z0lQIDAQAB");
        return jsencrypt.encrypt(msg);
    }

    login = async () => {

        this.formRef.current.validateFields().then(async (values) => {

            let params = {
                loginName: values.loginName,
                password: this.setEncrypt(values.loginPassword),
                // password: 'sYObhUd37mCiGrMxRxwLHcAY6Fax8VqIWJbwymPjsQPYVBv1a0HWdDGOamlcQ6JGFbMiIRKBwJkv19AXAJUN5B+RJCj6H46ER6NBsM531fDEBvYPSUedyu4FjJr35ivKj8n9xDVrnOUnV91wm/ghm9Xs37EYlU86g1Or6PiMvVCFx3jNtYad6jAAZ/Qn7rFoVuvPRJIVaNjD04Ubp2tlmO05QzSYzAsf4xqbXq8hR7nDlBY2uKlXYEZeLOe68DK0uJq75DLiipqsJ/FMhyCLDkypc2o3jakfoSfKKDdqEV3PM0jIR4BJnHT334J3mVDJDek4ocCAReFcigLYnSheHg==',
                captchaKey: this.state.captchaKey,
                platform: 'WEB',
                captcha: !!values.authCode ? values.authCode : ''
            }
            let resp = await fetchData("post", "/login", params)

            if (resp.code === 1) {
                localStorage.setItem('zhToken', resp.data.data.token)
                SetToken2(resp.data.data.token)
                const myData = await fetchData("get", "/check", null);
                if (myData.code === 1 && myData.data.code === 200) {
                    let data = myData.data.data
                    this.props.setPortalReducer({
                        myData: data,
                        token: resp.data.data.token,
                        name: data.name
                    })
                }

            } else if (resp.code === -1) {

                let message = resp.data.message
                if (message === '验证码错误') {
                    this.formRef.current.setFields([{
                        name: "authCode",
                        value: null,
                        errors: ['验证码错误']
                    }]);
                    this.refreshCode();
                } else {
                    this.formRef.current.setFields([{
                        name: "loginPassword",
                        value: null,
                        errors: [message]
                    }]);
                }
            }

        }).catch(errorInfo => {
            console.error(errorInfo);
        });
    }

    async refreshCode() {
        let params = { lastCaptchaKey: '' }
        let resp = await fetchData("get", "/captcha", params)
        if (resp.code === 1) {
            this.setState({
                authCode: resp.data.data.base64Image,
                captchaKey: resp.data.data.captchaKey
            });
        }
    }

    render() {

        return (
            <div style={{ overflowY: 'hidden', height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                <img
                    onDragStart={(e) => {
                        e.preventDefault();
                    }}
                    alt=""
                    style={{ width: '100%', height: '100%', position: 'absolute', objectFit: 'cover' }}
                    src={Bg}
                />
                <div id={'login-panel'} style={{ width: 350, height: 550, position: 'relative', display: 'flex', borderRadius: 15, boxShadow: 'rgb(141 140 140 / 15%) 0px 0px 15px 5px', marginRight: '15%' }}>


                    <div style={{ flex: 1, backgroundColor: 'white', borderRadius: 15 }}>

                        <div style={{ display: 'flex', background: '#5585ed', alignItems: 'center', justifyContent: 'center', height: '40%', color: 'white', borderTopRightRadius: 15, borderTopLeftRadius: 15 }}>
                            <div>
                                <div style={{ fontSize: 24, fontWeight: 400, paddingTop: 15 }}>{window.company}会议管理后台系统</div>
                            </div>


                        </div>
                        <div className="login-tr"></div>
                        <div
                            style={{ padding: '30px 20px' }}
                            id={'login-card'}
                        >
                            <Form style={{ width: '100%' }} ref={this.formRef} autoComplete="off">
                                <Form.Item
                                    name="loginName"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value) {
                                                    return Promise.reject('用户名不能为空!');
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        className="login-input"
                                        style={inputCss}
                                        prefix={<FontAwesomeIcon icon={faUser} style={faIconCss} />}
                                        placeholder={"用户名"}
                                        onPressEnter={this.login}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="loginPassword"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value) {
                                                    return Promise.reject('密码不能为空!');
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        className="login-input"
                                        style={inputCss}
                                        prefix={<FontAwesomeIcon icon={faKey} style={faIconCss} />}
                                        placeholder={"密码"}
                                        onPressEnter={this.login}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="authCode"
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value) {
                                                    return Promise.reject('验证码不能为空!');
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            },
                                        }),
                                    ]}
                                >
                                    <Input
                                        className="login-input"
                                        style={inputCss}
                                        prefix={<FontAwesomeIcon icon={faShieldAlt} style={faIconCss} />}
                                        placeholder={"验证码"}
                                        onPressEnter={this.login}
                                        suffix={
                                            <Tooltip title={'点击刷新'}>
                                                <img
                                                    src={this.state.authCode}
                                                    alt={'验证码'}
                                                    style={{ height: 24, width: 80, paddingRight: 5 }}
                                                    onClick={() => { this.refreshCode() }}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </Form.Item>

                            </Form>

                            <Button
                                size={"large"}
                                type={"primary"}
                                style={{ width: '100%', height: 36, borderRadius: '8px/14px' }}
                                onClick={this.login}
                            >
                                登录
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispachToProps = (dispatch) => {
    return {
        setPortalReducer: (target) => {
            dispatch({
                type: "setPortalReducer",
                payload: target
            });
        },
    }
};

export default connect(null, mapDispachToProps)(Login);
