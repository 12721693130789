import React, { Suspense } from 'react';
import { connect } from "react-redux";
import { HashRouter, Route, Switch } from "react-router-dom";
import Loading from "../common/extendedAntdWidget/Loading";
import Login from '../components/login/Login';
import { fetchData, SetToken2 } from "../utils/fetchData";
import Content from '../routes/Content';
// const SiteContent = React.lazy(() => import(/* webpackChunkName: "siteContent" */ "./siteContent"));
// import SiteContent from './siteContent';

class Portal extends React.Component {

    async componentDidMount() {

        let token = localStorage.getItem('zhToken');
        if (token) {
            SetToken2(token)
        }
        if (token) {
            let result = await fetchData("get", "/check", null);
            console.log(result)
            if (result.code === 1 && result.data.code === 200) {
                let data = result.data.data
                this.props.setPortalReducer({
                    myData: data,
                    token: token,
                    name: data.name
                })
            }
        } else {
            this.props.setPortalReducer({
                myData: {},
            })
        }
    }

    render() {
        return (
            !this.props.portal.myData
                ?
                <Loading />
                :
                <HashRouter>
                    <Switch>
                        <Route
                            path={"/login"}
                            exact
                            children={() => {
                                return <Suspense fallback={<Loading />}><Login /></Suspense>;
                            }}
                        />
                        <Route
                            path={"/"}
                            children={(data) => {
                                if (this.props.portal.myData.name) {
                                    return <Suspense fallback={<Loading />}><Content /></Suspense>
                                } else {
                                    return <Suspense fallback={<Loading />}><Login /></Suspense>;
                                }
                            }}
                        />
                    </Switch>
                </HashRouter>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        portal: state.portal
    }
};

const mapDispachToProps = (dispatch) => {
    return {

        setPortalReducer: (target) => {
            dispatch({
                type: "setPortalReducer",
                payload: target
            });
        },


    }
};

export default connect(mapStateToProps, mapDispachToProps)(Portal);